import "@atg-shared/micro-frontend/setPublicPath";
import log, {serverLogPlugin} from "@atg-shared/log";
import {initialiseLanguage} from "@atg-shared/language";
import {configureEmotionCache} from "atg-emotion-cache";

serverLogPlugin(log, "video-player");
log.setLevel("warn");
configureEmotionCache("video-player");

export const App = () => import(/* webpackChunkName: "App" */ "./components/App");

export const LivePlayerApp = () =>
    import(/* webpackChunkName: "LivePlayerApp" */ "./components/LivePlayerApp");

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});
